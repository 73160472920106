import styled from 'styled-components'

import { Section as GSSection } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, space, fontSizes, radii } = theme

export const Section = styled(GSSection)`
  padding-top: 0;

  @media (${media.tablet}) {
    padding-top: ${space['spacing-xl']};
    padding-bottom: ${space['spacing-xl']};
  }

  @media (${media.desktop}) {
    padding-bottom: 7.5rem;
  }
`

export const Content = styled.div`
  @media (${media.tablet}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: ${space['spacing-xxl']};
  }
`

export const ImageWrapper = styled.div`
  margin-left: -${space['spacing-md']};
  margin-right: -${space['spacing-md']};
  margin-bottom: ${space['spacing-xxl']};

  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
    min-width: calc(50% - ${space['spacing-sm']});
    margin: 0;
    border-radius: ${radii['corner-radius-sm']};
    overflow: hidden;
  }
`

export const Infos = styled.div`
  @media (${media.tablet}) {
    width: calc(50% - ${space['spacing-sm']});
  }

  h3,
  p:not(:last-of-type) {
    margin-bottom: ${space['spacing-md']};
  }

  @media (${media.desktop}) {
    h3 {
      margin-bottom: ${space['spacing-sm']};
      font-size: ${fontSizes['font-size-heading-3']};
    }

    p {
      margin-bottom: ${space['spacing-xl']};
    }
  }
`
