import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from "next/image"

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const SolarEnergy: FC = memo(() => (
  <S.Section bg="medium">
    <Grid col={{ phone: 1, tablet: 1 }}>
      <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
        <S.Content>
          <S.ImageWrapper>
            <NextImage
              src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/condo/energia-solar.png?updated=03072022`}
              alt="Energia solar"
              width="767"
              height="431"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto"
              }} />
          </S.ImageWrapper>

          <S.Infos>
            <Text as="h3" size="heading3" weight="medium">
              Energia Solar
            </Text>
            <Text color="var(--colors-neutral-text-weak)">Financiamos 100% do projeto de implantação.</Text>
            <Text weight="medium">Uma ação sustentável capaz de reduzir os gastos do condomínio a longo prazo.</Text>
          </S.Infos>
        </S.Content>
      </Col>
    </Grid>
  </S.Section>
))

SolarEnergy.displayName = 'SolarEnergy'

export default SolarEnergy
